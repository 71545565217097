/**
 * @name 返回农历年一整年的总天数
 * @param year 年份
 */
function getLunarYearDays(year) {
  let yearDays = 348;
  for (let i = 0x8000; i > 0x8; i >>= 1) {
    yearDays += LUNAR_YEAR_INFO[year - 1900] & i ? 1 : 0;
  }
  return yearDays + getLunarLeapDays(year);
}

/**
 * @name 返回农历年闰月是哪个月，若非闰月则返回0
 * @param year 年份
 */
function getLunarLeapMonth(year) {
  return LUNAR_YEAR_INFO[year - 1900] & 0xf;
}

/**
 * @name 返回农历年闰月的天数，若该年没有闰月则返回0
 * @param year 年份
 */
function getLunarLeapDays(year) {
  if (getLunarLeapMonth(year)) {
    return LUNAR_YEAR_INFO[year - 1900] & 0x10000 ? 30 : 29;
  }
  return 0;
}

/**
 * @name 农历各月天数
 * @param year
 * @returns
 */
function getLunarMonthDaysList(year) {
  const lunarYear = LUNAR_YEAR_INFO[year - 1900];
  const monthDays = [];
  for (let i = 4; i < 16; i++) {
    const monthDay = (lunarYear >> i) & 0x1 ? 30 : 29;
    monthDays.push(monthDay);
  }
  monthDays.reverse();
  // 添加闰月
  const leapM = getLunarLeapMonth(year);
  if (leapM) monthDays.splice(leapM, 0, getLunarLeapDays(year));
  return monthDays;
}

/** @name 获取农历称呼 */
export function getLunarChineseDay(day) {
  if (!day) return '';
  switch (day) {
    case 10:
      return '\u521D\u5341';
    case 20:
      return '\u4E8C\u5341';
    case 30:
      return '\u4E09\u5341';
    default:
      return `${LUNAR_PREFIX[Math.floor(day / 10)]}${LUNAR_SUFFIX[day % 10]}`;
  }
}

/** @name 获取农历日期 */
export function getLunarDate(time) {
  const year = new Date(time).getFullYear();
  if (year < 1901 || year > 2100) return null;
  const day = new Date(time);

  // 参照日期 1901-02-19 正月初一
  let offset =
    (Date.UTC(day.getFullYear(), day.getMonth(), day.getDate()) -
      Date.UTC(1901, 1, 19)) /
    86400000;
  let temp = 0;
  let lunarY = 1901;
  for (; lunarY < 2101 && offset > 0; lunarY++) {
    temp = getLunarYearDays(lunarY);
    offset -= temp;
  }
  if (offset < 0) {
    offset += temp;
    lunarY--;
  }

  // 农历年、月、日
  let isLeap = false;
  let lunarM = 0;
  const monthDays = getLunarMonthDaysList(lunarY);
  const leapM = getLunarLeapMonth(lunarY);

  let tempM = 0;
  if (offset > 0) {
    for (; lunarM < monthDays.length && offset > 0; lunarM++) {
      tempM = monthDays[lunarM];
      offset -= tempM;
    }
    if (offset === 0) {
      lunarM++;
    }
    if (offset < 0) {
      offset += tempM;
    }
  } else {
    // 补偿公历1901年2月的农历信息
    if (offset === -23) {
      return {
        lunarY,
        lunarM: 12,
        lunarD: 8,
        isLeap: false,
      };
    }
  }

  // 矫正闰年月
  if (leapM) {
    if (lunarM === leapM + 1) {
      isLeap = true;
    }
    if (lunarM >= leapM + 1) {
      lunarM--;
    }
  }

  return {
    year: lunarY,
    month: lunarM,
    date: ++offset,
    isLeap,
  };
}

/**
 * @name 日期转农历称呼速查表
 * @Array Of Property
 * @trans ['初','十','廿','卅']
 * @return Cn string
 */
const LUNAR_PREFIX = ['\u521d', '\u5341', '\u5eff', '\u5345'];

/**
 * 数字转中文速查表
 * @Array Of Property
 * @trans ['日','一','二','三','四','五','六','七','八','九','十']
 * @return Cn string
 */
const LUNAR_SUFFIX = [
  '\u65E5',
  '\u4E00',
  '\u4E8C',
  '\u4E09',
  '\u56DB',
  '\u4E94',
  '\u516D',
  '\u4E03',
  '\u516B',
  '\u4E5D',
  '\u5341',
];

/**
 * @description 前4位，即0在这一年是润年时才有意义，它代表这年润月的大小月，为1则润大月，为0则润小月。
 中间12位，即4bd，每位代表一个月，为1则为大月，为0则为小月。
 最后4位，即8，代表这一年的润月月份，为0则不润。首4位要与末4位搭配使用。
 */
const LUNAR_YEAR_INFO = [
  // 1900-1909
  0x04bd8,
  0x04ae0,
  0x0a570,
  0x054d5,
  0x0d260,
  0x0d950,
  0x16554,
  0x056a0,
  0x09ad0,
  0x055d2,
  // 1910-1919
  0x04ae0,
  0x0a5b6,
  0x0a4d0,
  0x0d250,
  0x1d255,
  0x0b540,
  0x0d6a0,
  0x0ada2,
  0x095b0,
  0x14977,
  // 1920-1929
  0x04970,
  0x0a4b0,
  0x0b4b5,
  0x06a50,
  0x06d40,
  0x1ab54,
  0x02b60,
  0x09570,
  0x052f2,
  0x04970,
  // 1930-1939
  0x06566,
  0x0d4a0,
  0x0ea50,
  0x06e95,
  0x05ad0,
  0x02b60,
  0x186e3,
  0x092e0,
  0x1c8d7,
  0x0c950,
  // 1940-1949
  0x0d4a0,
  0x1d8a6,
  0x0b550,
  0x056a0,
  0x1a5b4,
  0x025d0,
  0x092d0,
  0x0d2b2,
  0x0a950,
  0x0b557,
  // 1950-1959
  0x06ca0,
  0x0b550,
  0x15355,
  0x04da0,
  0x0a5b0,
  0x14573,
  0x052b0,
  0x0a9a8,
  0x0e950,
  0x06aa0,
  // 1960-1969
  0x0aea6,
  0x0ab50,
  0x04b60,
  0x0aae4,
  0x0a570,
  0x05260,
  0x0f263,
  0x0d950,
  0x05b57,
  0x056a0,
  // 1970-1979
  0x096d0,
  0x04dd5,
  0x04ad0,
  0x0a4d0,
  0x0d4d4,
  0x0d250,
  0x0d558,
  0x0b540,
  0x0b6a0,
  0x195a6,
  // 1980-1989
  0x095b0,
  0x049b0,
  0x0a974,
  0x0a4b0,
  0x0b27a,
  0x06a50,
  0x06d40,
  0x0af46,
  0x0ab60,
  0x09570,
  // 1990-1999
  0x04af5,
  0x04970,
  0x064b0,
  0x074a3,
  0x0ea50,
  0x06b58,
  0x055c0,
  0x0ab60,
  0x096d5,
  0x092e0,
  // 2000-2009
  0x0c960,
  0x0d954,
  0x0d4a0,
  0x0da50,
  0x07552,
  0x056a0,
  0x0abb7,
  0x025d0,
  0x092d0,
  0x0cab5,
  // 2010-2019
  0x0a950,
  0x0b4a0,
  0x0baa4,
  0x0ad50,
  0x055d9,
  0x04ba0,
  0x0a5b0,
  0x15176,
  0x052b0,
  0x0a930,
  // 2020-2029
  0x07954,
  0x06aa0,
  0x0ad50,
  0x05b52,
  0x04b60,
  0x0a6e6,
  0x0a4e0,
  0x0d260,
  0x0ea65,
  0x0d530,
  // 2030-2039
  0x05aa0,
  0x076a3,
  0x096d0,
  0x04afb,
  0x04ad0,
  0x0a4d0,
  0x1d0b6,
  0x0d250,
  0x0d520,
  0x0dd45,
  // 2040-2049
  0x0b5a0,
  0x056d0,
  0x055b2,
  0x049b0,
  0x0a577,
  0x0a4b0,
  0x0aa50,
  0x1b255,
  0x06d20,
  0x0ada0,
  // 2050-2059
  0x14b63,
  0x09370,
  0x049f8,
  0x04970,
  0x064b0,
  0x168a6,
  0x0ea50,
  0x06b20,
  0x1a6c4,
  0x0aae0,
  // 2060-2069
  0x0a2e0,
  0x0d2e3,
  0x0c960,
  0x0d557,
  0x0d4a0,
  0x0da50,
  0x05d55,
  0x056a0,
  0x0a6d0,
  0x055d4,
  // 2070-2079
  0x052d0,
  0x0a9b8,
  0x0a950,
  0x0b4a0,
  0x0b6a6,
  0x0ad50,
  0x055a0,
  0x0aba4,
  0x0a5b0,
  0x052b0,
  // 2080-2089
  0x0b273,
  0x06930,
  0x07337,
  0x06aa0,
  0x0ad50,
  0x14b55,
  0x04b60,
  0x0a570,
  0x054e4,
  0x0d160,
  // 2090-2099
  0x0e968,
  0x0d520,
  0x0daa0,
  0x16aa6,
  0x056d0,
  0x04ae0,
  0x0a9d4,
  0x0a2d0,
  0x0d150,
  0x0f252,
  // 2100
  0x0d520,
];
