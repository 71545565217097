<template>
  <div style="min-height: 400px;">
    <div :class="$style.tipText">
      <div>
        累计在场时间：{{ record.enterDuration }}小时；累计离场时间：{{
          record.leaveDuration
        }}小时
      </div>
      <div>注意：如需获得更精准时段，可降低定位上报的时间间隔！</div>
    </div>
    <a-table
      :columns="columns"
      style="height: 450px;"
      :scroll="{ y: 300 }"
      :pagination="false"
      :loading="loading"
      :transformCellText="({ text }) => text || '-'"
      :dataSource="tableList"
    >
      <template slot="action" slot-scope="record">
        <a @click="handleDetail(record)">详情</a>
      </template>
      <template slot="time" slot-scope="record">
        {{ record.inTime ? `${$moment(record.inTime).format('HH:mm')}-` : '-'
        }}{{
          record.outTime ? `${$moment(record.outTime).format('HH:mm')}` : ''
        }}
      </template>
    </a-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getActivityDetailList } from '@/services/smart-hat/activity-record';
import { createModal } from '@triascloud/x-components';
import Trail from './hat-trail.vue';
import { getDeviceDetail } from '@/services/smart-hat/device';

@Component({
  components: {},
})
export default class ActivityDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) record;

  mounted() {
    this.getTableList();
  }
  loading = false;
  tableList = [];
  async getTableList() {
    this.loading = true;
    try {
      const params = {
        recordId: this.record.recordId,
        size: 500,
        current: 1,
      };
      this.tableList = (await getActivityDetailList(params)).records;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  status = {
    IN: '在场',
    OUT: '离场',
  };
  get columns() {
    return [
      {
        title: '时段',
        ellipsis: true,
        scopedSlots: { customRender: 'time' },
      },
      {
        title: '状态',
        customRender: (text, record) => this.status[record.status],
      },
      {
        title: '所在地区',
        dataIndex: 'idxGeofenceName',
        ellipsis: true,
      },
      {
        title: '时长',
        dataIndex: 'totalDuration',
        customRender: text => (text < 0 ? '-' : text + '小时'),
      },
      {
        title: '活动详情',
        width: 120,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  hatColor = 'ORANGE';
  async getDeviceDetail(deviceId) {
    try {
      const { color } = await getDeviceDetail(deviceId);
      this.hatColor = color;
    } catch {
      this.hatColor = 'ORANGE';
    }
  }
  async handleDetail(record) {
    await this.getDeviceDetail(record.idxDeviceId);
    createModal(
      () => (
        <Trail
          deviceId={record.idxDeviceId}
          fenceId={record.idxGeofenceId}
          date={record.inTime}
          hatColor={this.hatColor}
        />
      ),
      {
        width: 850,
        maskClosable: false,
        title: `活动详情`,
      },
    );
  }
}
</script>
<style lang="less" module>
.tipText {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
