<template>
  <div :class="$style.detailWrap">
    <header>
      <div>
        {{ this.record.userName }}{{ $t('hat.clockRecord.punchDetails') }} ({{
          this.record.date
        }})
      </div>
    </header>
    <a-table
      :columns="columns()"
      :data-source="data"
      :pagination="false"
      :loading="loading"
    >
      <template slot="operation" slot-scope="record">
        <a href="javascript:;" @click="Detail(record)">{{
          $t('hat.clockRecord.detailed')
        }}</a>
      </template>
    </a-table>
    <footer>
      <a-button
        size="small"
        type="primary"
        @click="handleCloce"
        style="margin-left: 10px;"
      >
        {{ $t('hat.clockRecord.confirm') }}
      </a-button>
      <a-button size="small" @click="handleCloce">
        {{ $t('enterpriseManagement.camera.detail.closetxt') }}
      </a-button>
    </footer>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import PunchMap from './punch-map.vue';
import { createModal } from '@triascloud/x-components';
import { getAttendanceDetail } from '../../../../services/attendance/list';
@Component({ PunchMap })
export default class PunchDetail extends Vue {
  visible = true;
  @Prop({ type: Object, default: () => {} }) record;
  loading = false;
  columns() {
    const $t = this.$t;
    return [
      {
        title: $t('hat.clockRecord.workingTimeRange'),
        dataIndex: 'time',
        width: 120,
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.firstTimeClockIn'),
        dataIndex: 'firstTime',
        width: 130,
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.lastTimeClockIn'),
        dataIndex: 'lastTime',
        width: 130,
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.workHours'),
        dataIndex: 'manHour',
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.location'),
        width: 220,
        dataIndex: 'location',
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.actualTime'),

        ellipsis: true,
        width: 100,
        scopedSlots: { customRender: 'operation' },
      },
    ];
  }
  data = [];

  async mounted() {
    const { attendanceId } = this.record;
    this.loading = true;
    const detail = await getAttendanceDetail({ attendanceId });
    this.data = detail.map(item => {
      var { firstTime, lastTime, manHour } = item;
      var [workTime, workOfftime] = item.time.split('-');
      firstTime = this.isIntime(workTime, firstTime);
      lastTime = this.isEarly(workOfftime, lastTime);
      var manHour1 = manHour == -1 ? '' : manHour;
      return { ...item, firstTime, lastTime, manHour: manHour1 };
    });
    this.loading = false;
  }
  //计算正常 早退
  isEarly(workTime, lastTime) {
    const $t = this.$t;
    if (lastTime) {
      var d = this.record.date.split(' ')[0] + ' ' + workTime + ':00';
      var workNumber = new Date(d).getTime();
      var lastNumber = new Date(lastTime).getTime();
      var is =
        workNumber < lastNumber
          ? $t('hat.clockRecord.normal')
          : $t('hat.clockRecord.leaveEarlier');
      return this.$moment(lastTime).format('HH:mm:ss') + is;
    } else {
      return '';
    }
  }
  //计算正常 迟到
  isIntime(workTime, firstTime) {
    const $t = this.$t;
    if (firstTime) {
      var d = this.record.date.split(' ')[0] + ' ' + workTime + ':00';
      var workNumber = new Date(d).getTime();
      var firstNumber = new Date(firstTime).getTime();
      var is =
        workNumber > firstNumber
          ? $t('hat.clockRecord.normal')
          : $t('hat.clockRecord.beLate');
      return this.$moment(firstTime).format('HH:mm:ss') + is;
    } else {
      return '';
    }
  }
  handleCloce() {
    this.$listeners.handleClose();
  }
  Detail(record) {
    const userId = this.record.userId;
    const modal = createModal(
      h =>
        h(PunchMap, {
          props: { record: { ...record, userId } },
          on: {
            handleClose: () => {
              modal.handleClose();
            },
          },
        }),
      {
        className: 'x-tenant-change--modal',
        closable: true,
        maskClosable: false,
        width: 860,
      },
    );
  }
}
</script>

<style lang="less" module>
.detailWrap {
  header {
    height: 40px;
    color: var(--font);
    font-weight: 800;
    display: flex;
    justify-content: space-between;
  }

  footer {
    height: 40px;
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>
