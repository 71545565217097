import request from '../request';

const serviceName = '/iot-smart-helmet/pm';

/**
 * @name 分页获取项目管理详情
 */
export function getPagePm(data) {
  return request(`${serviceName}/pagePm`, {
    method: 'GET',
    body: data,
  });
}
