import request from '../request';

const AttendanceList = '/triascloud-iot-smart-helmet/attendance/list';
const AttendanceDetail = '/triascloud-iot-smart-helmet/attendance/detail';
const staffUrl = '/tenant-center/address/aggregateQuery';
const externalUrl = '/tenant-center/externalContactPerson/page/list';
const innerlUrl = '/tenant-center/tenant/member/list';
/**
 * @name 考勤记录
 */
export function getAttendanceList(options = {}) {
  return request(AttendanceList, {
    method: 'GET',
    body: options,
    closeMessage: true,
  });
}
export function getAttendanceDetail(options) {
  const { attendanceId } = options;
  return request(`${AttendanceDetail}?attendanceId=${attendanceId}`, {
    method: 'GET',
    closeMessage: true,
  });
}
export function reqGetStaff(keyword) {
  return request(staffUrl, {
    method: 'post',
    body: {
      deptScopeList: [],
      memberScopeList: [],
      roleScopeList: [],
      keyword: keyword,
      type: ['DEPT', 'MEMBER', 'EXTERNAL'],
    },
    closeMessage: true,
  });
}
export function reqGetStaffInner() {
  return request(innerlUrl, {
    method: 'post',
    body: { query: { size: 200, current: 1 }, memberScopeList: [] },
    closeMessage: true,
  });
}
export function reqGetStaffExternal() {
  return request(`${externalUrl}?size=200&current=1`, {
    method: 'get',
    closeMessage: true,
  });
}
