<template>
  <div
    :class="[
      $style.trajectory,
      $store.state.home.bigScreen ? $style.wrapFull : '',
    ]"
  >
    <div
      :class="[
        $style.select,
        $store.state.home.bigScreen ? $style.insideFull : '',
      ]"
    >
      <a-range-picker
        v-model="searchForm.timeRange"
        style="width: 320px; margin-right: 10px"
        :class="[$store.state.home.bigScreen ? $style.fullBg : '']"
        :show-time="{ format: 'YYYY-MM-DD HH:mm:ss' }"
        format="YYYY-MM-DD HH:mm:ss"
        :placeholder="['开始日期', '结束日期']"
        @ok="addLineByCalendar"
        :disabled-date="disabledDate"
        @calendarChange="calendarPriceRangeChange"
        :disabled="play"
      />
      <a-select
        style="width: 105px"
        :class="[$store.state.home.bigScreen ? $style.fullBg : '']"
        v-model="searchForm.fence"
        placeholder="电子围栏"
        allow-clear
        @change="handleSelectFence"
        :disabled="play"
      >
        <a-select-option
          v-for="item in fenceList"
          :key="item.geofenceId"
          :value="item.geofenceId"
        >
          {{ item.geofenceName }}
        </a-select-option>
      </a-select>
      <a-select
        style="width: 75px; margin-right: 10px"
        :class="[$store.state.home.bigScreen ? $style.fullBg : '']"
        v-model="searchForm.speed"
        placeholder="1.0X"
        allow-clear
        @change="handleSelectSpeed"
        :disabled="play"
      >
        <a-select-option
          v-for="(item, index) in speed"
          :key="index"
          :value="item"
        >
          {{ item }}
        </a-select-option>
      </a-select>
      <a-button
        type="primary"
        size="small"
        style="min-width: 50px; height: 30px"
        @click="handlePlay"
      >
        {{ !play ? '播放' : '停止' }}
      </a-button>
    </div>
    <div :class="$style.map">
      <TrajectoryMap
        :color="hatColor"
        ref="trace"
        @stop-play="handleStopPlay"
        @map-loaded="handleMapLoaded"
      />
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import TrajectoryMap from '@/views/hat/device/manage-model/trajectory-map.vue';
import { getTrace } from '@/services/trace/index';
import { getFenceListById } from '@/services/smart-hat/geofence';

@Component({
  components: {
    TrajectoryMap,
  },
})
export default class HatTrail extends Vue {
  @Prop({ type: String, default: '' }) deviceId;
  @Prop({ type: String, default: 'ORANGE' }) hatColor;
  @Prop({ type: String, default: '' }) fenceId;
  @Prop({ type: Number | String }) date;

  fenceList = [];
  searchForm = {
    timeRange: [],
    projectId: undefined,
    speed: undefined,
    userId: undefined,
    fence: undefined,
  };
  play = false;
  speed = [
    '0.1X',
    '0.5X',
    '0.8X',
    '1.0X',
    '1.2X',
    '1.5X',
    '2.0X',
    '5.0X',
    '10X',
    '20X',
  ];
  created() {
    this.$store.commit('home/setBigScreen', false);
  }
  mounted() {
    this.setDateTime();
  }
  setDateTime() {
    this.searchForm.timeRange = [
      this.$moment(this.date || undefined)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      this.$moment(this.date || undefined)
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
    ];
  }
  addLineByCalendar() {
    this.dataLoaded = false;
    this.addTraceLine();
  }
  disabledDate(current) {
    const selectDate = this.$moment(this.selectPriceDate).valueOf();
    const offsetDays = 30 * 24 * 60 * 60 * 1000;
    // 禁用选中时间前后的{{offsetDays}}天
    return (
      this.$moment(current).valueOf() > selectDate + offsetDays ||
      this.$moment(current).valueOf() < selectDate - offsetDays ||
      current > this.$moment().endOf('day')
    );
  }
  selectPriceDate = '';
  calendarPriceRangeChange(date) {
    this.selectPriceDate = date[0];
  }
  mapLoaded = false;
  async handleMapLoaded() {
    this.mapLoaded = true;
    // 地图加载结束后才能加载轨迹和围栏
    await this.getFenceList();
    this.addTraceLine();
    this.handleSelectFence(this.fenceId);
  }
  dataLoaded = false;
  mapData = {};
  async addTraceLine() {
    const { projectId, userId } = this.searchForm;
    let body = {
      deviceId: this.deviceId,
      projectId,
      userId,
    };
    if (this.searchForm.timeRange.length) {
      body.startTime = this.$moment(this.searchForm.timeRange[0]).valueOf();
      body.endTime = this.$moment(this.searchForm.timeRange[1]).valueOf();
    }
    const res = await getTrace(body);
    const pathList = res
      .map(item => {
        return [item.lng, item.lat];
      })
      .reverse();
    const timeList = res
      .map(item => {
        return this.$moment(item.time).format('YYYY-MM-DD HH:mm:ss');
      })
      .reverse();
    const avatarList = res
      .map(item => {
        return item.userAvatar;
      })
      .reverse();
    this.mapData = {
      path: pathList,
      time: timeList,
      avatar: avatarList,
    };
    this.dataLoaded = true;
  }
  get loaded() {
    if (this.mapLoaded && this.dataLoaded) {
      return true;
    }
    return false;
  }
  @Watch('loaded', { immediate: true })
  onLoaded(val) {
    if (val) {
      this.$refs.trace.createTracePath2(this.mapData);
    }
  }
  handleSelectFence(id) {
    this.$refs.trace.deleteCircle();
    id && this.$refs.trace.handleFenceChange(id, this.fenceList);
  }
  handleSelectSpeed() {
    if (!this.searchForm.speed) return;
    this.$refs.trace.setSpeed(this.searchForm.speed);
  }
  handleStopPlay() {
    this.play = false;
  }
  handlePlay() {
    if (!this.play) {
      this.$refs.trace.play();
      this.play = true;
    } else {
      this.$refs.trace.stop();
      this.play = false;
    }
  }
  async getFenceList() {
    this.fenceList = await getFenceListById({
      deviceGroupIds: this.deviceId,
    });
    this.searchForm.fence = this.fenceId;
  }
}
</script>

<style lang="less" module>
.trajectory {
  width: 100%;
  height: 482px;
  .select {
    display: flex;
    align-items: center;
  }
  .insideFull {
    position: fixed;
    top: 10px;
    height: max-content;
    right: 120px;
    z-index: 9999;
  }
  .map {
    margin-top: 15px;
  }
}
.wrapFull {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: -20px;
  left: 0;
  z-index: 9999;
}
.fullBg {
  background-color: #fff;
}
</style>
