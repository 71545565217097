<template>
  <a-spin :spinning="loading">
    <a-calendar ref="calendar" v-model="value">
      <template #headerRender="{value}">
        <div :class="$style.calendarHeader">
          <div :class="$style.dateChange">
            <div :class="$style.action" @click="dateChange('last', value)">
              <a-icon type="left" />
            </div>
            <span>{{ moment(value).format('YYYY-MM') }}</span>
            <div :class="$style.action" @click="dateChange('next', value)">
              <a-icon type="right" />
            </div>
          </div>
          <a-select
            show-search
            v-model="userId"
            :placeholder="$t('hat.clockRecord.allMembers')"
            style="width: 200px;"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearch"
            @change="handleChange"
          >
            <a-select-opt-group>
              <span slot="label"
                ><a-icon type="user" />{{ $t('hat.clockRecord.members') }}</span
              >
              <a-select-option value="u1" v-show="false">
                {{ $t('hat.clockRecord.allMembers') }}
              </a-select-option>
              <a-select-option
                v-for="item in data.member"
                :key="item.pkId"
                :value="item.pkId"
              >
                {{ item.memberName }}
              </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group>
              <span slot="label"
                ><a-icon type="user" />{{
                  $t('hat.clockRecord.external')
                }}</span
              >
              <!-- antDesign的bug不加这个空option会报错 -->
              <a-select-option value="u0" v-show="false">
                {{ $t('hat.clockRecord.allMembers') }}
              </a-select-option>
              <a-select-option
                v-for="item in data.external"
                :key="item.pkId"
                :value="item.pkId"
              >
                {{ item.entMemberName }}
              </a-select-option>
            </a-select-opt-group>
          </a-select>
        </div>
      </template>
      <template #dateFullCellRender="value">
        <div
          :class="[
            $style.item,
            calendarData[getDayTimestamp(value)] ? $style.itemActive : '',
          ]"
        >
          <div :class="$style.title" @click="openDetail(value)">
            <span>{{ getDay(value) }}</span>
            <span>{{ getLunarChineseDay(value) }}</span>
          </div>
          <div
            :class="$style.content"
            v-if="calendarData[getDayTimestamp(value)]"
          >
            <div
              :class="$style.contentItem"
              v-for="item in calendarData[getDayTimestamp(value)]"
              @click="openMap(item)"
              :key="item.pkId"
            >
              <div
                :class="[
                  $style.dot,
                  item.ciStatus != 1 || item.coStatus != 1
                    ? $style.dotError
                    : '',
                ]"
              ></div>
              {{ item.shiftTime }}
            </div>
          </div>
        </div>
      </template>
    </a-calendar>
  </a-spin>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getLunarChineseDay, getLunarDate } from './util';
import moment from 'moment';
import { calendarDetail } from '@/services/smart-hat/attendance';
import { createModal } from '@triascloud/x-components';
import AttendanceDetail from './detail.vue';
import PunchMap from '../punch-map.vue';
import {
  reqGetStaffInner,
  reqGetStaff,
  reqGetStaffExternal,
} from '@/services/attendance/list';
import { cloneDeep } from 'lodash';
import { unipue } from './util/index';

@Component({
  comments: {},
})
export default class Calendar extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
  getLunarChineseDay(val) {
    return getLunarChineseDay(getLunarDate(val).date);
  }

  value = '';
  userId = '';
  data = {
    external: {},
    member: {},
  };
  dataSave = {
    external: {},
    member: {},
  };
  searchVal = undefined;

  moment = moment;

  getDay(val) {
    return moment(val).get('date');
  }

  getDayTimestamp(times) {
    return +new Date(moment(times).format('YYYY-M-D'));
  }

  calendarData = {};
  loading = false;
  async init() {
    try {
      this.loading = true;
      const res = await calendarDetail({
        userId: this.userId,
        endTime: moment(this.value)
          .endOf('month')
          .valueOf(),
        startTime: moment(this.value)
          .startOf('month')
          .valueOf(),
      });
      res.forEach(item => {
        this.$set(this.calendarData, item.date, item.ciDataDetails);
      });
    } finally {
      this.loading = false;
    }
  }
  modal = null;
  openDetail(val) {
    const detail = this.data[this.getDayTimestamp(val)];
    if (!detail) return;
    const title = `${detail[0].checkInUserName}打卡详情(${moment(val).format(
      'YYYY-MM-DD',
    )})`;
    this.modal = createModal(
      () => (
        <AttendanceDetail data={detail} onClose={() => this.handleClose()} />
      ),
      {
        title,
        className: 'x-tenant-change--modal',
        closable: true,
        maskClosable: false,
        width: 860,
      },
    );
  }
  statusEnum = {
    6: '无效',
    5: '早退',
    4: '矿工',
    3: '严重迟到',
    2: '迟到',
    1: '正常',
    0: '未打卡',
    '-1': '忘记打卡',
    '-2': '休息',
  };
  openMap(record) {
    const modal = createModal(
      h =>
        h(PunchMap, {
          props: {
            record: {
              userId: record.checkInUserId,
              firstLocation: record.ciLngLat,
              lastLocation: record.coLngLat,
              firstTime:
                moment(record.ciTime).format('HH:mm:ss') +
                this.statusEnum[record.ciStatus],
              lastTime:
                moment(record.coTime).format('HH:mm:ss') +
                this.statusEnum[record.coStatus],
            },
          },
          on: {
            handleClose: () => {
              modal.handleClose();
            },
          },
        }),
      {
        className: 'x-tenant-change--modal',
        closable: true,
        maskClosable: false,
        width: 860,
      },
    );
  }

  handleClose() {
    this.modal.handleClose();
  }

  async staffList() {
    const current = await reqGetStaff(this.record.userName);
    const { records: member } = await reqGetStaffInner();
    const { records: external } = await reqGetStaffExternal();
    this.data.member = unipue([...current.member, ...member]);
    this.data.external = unipue([...current.external, ...external]);
    this.dataSave = cloneDeep(this.data);
  }

  async fetch(value) {
    const result = await reqGetStaff(value);
    this.data = result;
    if (!value) {
      this.data = this.dataSave;
    }
  }

  dateChange(type, value) {
    if (type == 'next') {
      this.value = moment(value).add(1, 'months');
    } else {
      this.value = moment(value).subtract(1, 'months');
    }

    this.init();
  }

  handleSearch(value) {
    this.searchVal = value;
    this.fetch(value);
  }

  async handleChange(value) {
    this.userId = value;
    this.calendarData = {};
    this.init();
  }

  async created() {
    await this.staffList();
    this.value = moment(this.record.date);
    this.userId = this.record.userId;
    this.init();
  }
}
</script>
<style lang="less" module>
:global(.ant-fullcalendar-fullscreen .ant-fullcalendar-date) {
  border: none;
  margin: 0;
}
:global(.ant-fullcalendar td) {
  border: 1px solid var(--border);
  box-sizing: border-box;
}
:global(.ant-fullcalendar-fullscreen .ant-fullcalendar-column-header) {
  padding: 10px 20px;
  text-align: center;
  background-color: var(--primary);
  color: #fff;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1.8;
  padding: 5px 20px;
}
.content {
  overflow: auto;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.contentItem {
  background-color: var(--primary-fade-10);
  padding: 3px 10px;
  max-width: 100%;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.itemActive {
  // color: var(--primary);
  .title {
    cursor: pointer;
  }
}
.dot {
  width: 6px;
  height: 6px;
  background: var(--success);
  border-radius: 50%;
}
.dotError {
  background: var(--delete);
}
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.calendarHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 12px;
  gap: 20px;
}
.dateChange {
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;

  .action {
    background-color: var(--primary-fade-10);
    color: var(--primary);
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 4px;
    transition: color 0.3s;
    &:hover {
      color: #fff;
      background-color: var(--primary);
    }
  }
}
</style>
