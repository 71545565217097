import request from '../request';

const serviceName = '/iot-smart-helmet/activityRecord';

export function getActivityRecordList(data) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    body: data,
  });
}

export function getActivityDetailList(data) {
  return request(`${serviceName}/detailList`, {
    method: 'GET',
    body: data,
  });
}

export function exportRecordExcel(data) {
  return request(`${serviceName}/exportRecordExcel`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 实时在场人数明细列表
 */
export function realPresenceDetail(data) {
  return request(`${serviceName}/realPresenceDetail`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 平均在场时长明细列表
 */
export function realAvgDetail(data) {
  return request(`${serviceName}/realAvgDetail`, {
    method: 'GET',
    body: data,
  });
}
