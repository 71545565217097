/** @name 周 */
export const WEEK = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];

/** @name 公历节日 */
export const GREGORIAN_FESTIVAL = {
  '1-1': '元旦',
  '3-8': '妇女节',
  '3-12': '植树节',
  '5-1': '劳动节',
  '5-4': '青年节',
  '6-1': '儿童节',
  '7-1': '建党节',
  '8-1': '建军节',
  '9-10': '教师节',
  '10-1': '国庆节',
};

/** @name 农历节日 */
export const LUNAR_FESTIVAL = {
  '1-1': '春节',
  '1-15': '元宵节',
  '5-5': '端午节',
  '7-7': '乞巧节',
  '8-15': '中秋节',
  '9-9': '重阳节',
  '12-8': '腊八节',
  '12-23': '小年',
};

export function unipue(arr) {
  const _arr = [];
  const map = new Map();
  for (const item of arr) {
    if (!map.has(item.pkId)) {
      _arr.push(item);
      map.set(item.pkId, 1);
    }
  }

  return _arr;
}

export * from './lunar';
export * from './solar-term';
export * from './time';
