<template>
  <div :class="$style.detailWrap">
    <a-table :columns="columns()" :data-source="data" :pagination="false">
      <template #operation="record">
        <a href="javascript:;" @click="Detail(record)">{{
          $t('hat.clockRecord.detailed')
        }}</a>
      </template>
    </a-table>
    <footer>
      <a-button
        size="small"
        type="primary"
        @click="handleCloce"
        style="margin-left: 10px;"
      >
        {{ $t('hat.clockRecord.confirm') }}
      </a-button>
      <a-button size="small" @click="handleCloce">
        {{ $t('enterpriseManagement.camera.detail.closetxt') }}
      </a-button>
    </footer>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import PunchMap from '../punch-map.vue';
import { createModal } from '@triascloud/x-components';
import moment from 'moment';

@Component({ components: { PunchMap } })
export default class AttendanceDetail extends Vue {
  @Prop({ type: Array, default: () => [] }) data;
  columns() {
    const $t = this.$t;
    return [
      {
        title: $t('hat.clockRecord.workingTimeRange'),
        dataIndex: 'shiftTime',
        width: 120,
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.firstTimeClockIn'),
        dataIndex: 'ciTime',
        width: 150,
        ellipsis: true,
        customRender: (text, record) => {
          return text ? (
            <span>
              {moment(record.ciTime).format('HH:mm:ss') +
                this.statusEnum[record.ciStatus]}
            </span>
          ) : (
            this.statusEnum[record.ciStatus]
          );
        },
      },
      {
        title: $t('hat.clockRecord.lastTimeClockIn'),
        dataIndex: 'coTime',
        width: 150,
        ellipsis: true,
        customRender: (text, record) => {
          return text ? (
            <span>
              {moment(record.coTime).format('HH:mm:ss') +
                this.statusEnum[record.coStatus]}
            </span>
          ) : (
            this.statusEnum[record.coStatus]
          );
        },
      },
      {
        title: $t('hat.clockRecord.workHours'),
        width: 100,
        dataIndex: 'ciShiftMinute',
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.location'),
        width: 220,
        dataIndex: 'attAddress',
        ellipsis: true,
      },
      {
        title: $t('hat.clockRecord.actualTime'),
        ellipsis: true,
        width: 100,
        scopedSlots: { customRender: 'operation' },
      },
    ];
  }

  statusEnum = {
    6: '无效',
    5: '早退',
    4: '矿工',
    3: '严重迟到',
    2: '迟到',
    1: '正常',
    0: '未打卡',
    '-1': '忘记打卡',
    '-2': '休息',
  };

  async mounted() {}

  handleCloce() {
    this.$emit('close');
  }
  Detail(record) {
    const modal = createModal(
      h =>
        h(PunchMap, {
          props: {
            record: {
              userId: record.checkInUserId,
              firstLocation: record.ciLngLat,
              lastLocation: record.coLngLat,
              firstTime: moment(record.ciTime).format('HH:mm:ss'),
              lastTime: moment(record.coTime).format('HH:mm:ss'),
            },
          },
          on: {
            handleClose: () => {
              modal.handleClose();
            },
          },
        }),
      {
        className: 'x-tenant-change--modal',
        closable: true,
        maskClosable: false,
        width: 860,
      },
    );
  }
}
</script>

<style lang="less" module>
.detailWrap {
  footer {
    height: 40px;
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>
